.loading-mask {
  position: fixed;
  background: rgba($off-white, .85);
  z-index: 999;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  .loader{
    width: 100%;
    position: absolute;
    text-align: center;
    top: 50%;
    margin-top: -12.5vh;
  }
}
