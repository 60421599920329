@import '../../styles/settings';
@import '../../styles/cas/mixins';

@import '../../Amazon_Pay/styles/checkout';

%checkout-panel{
  box-shadow: $global-box-shadow;
  border-radius: $global-radius;
  background-color: $white;
}

@import "checkout/header";
@import "checkout/payment-tabs";
@import "checkout/progress-bar";
@import "checkout/summary-block";
@import "checkout/shipping-step";
@import "checkout/payment-step";
@import "checkout/footer";

.checkout-page{
  background-color: get-color('light-vanilla');
  .page-main{
    margin-top:0;
  }
    @include callout(alert);
    @include callout(error);
    @include callout(success);
}
.paypal-express-review {
   .product-image-photo{
       width: 50px;
   }
}

.payment-method-note{
  margin-bottom: 1rem;
  padding: 1rem;
  background: $white;
  border: $global-border;
  border-radius: $global-radius;
}
.checkout-agreements-block{
  margin: 1rem 0;
}
.checkout-shipping-address {
    select {
        margin-bottom: rem-calc(34);
    }
}
.address-information-action{
  margin: 1rem 0 0.5rem;
  &:before{
    content: url('#{$svg-icons-path}pencil.svg');
    width: 17px;
    display: inline-block;
    margin-right: .25rem;
  }
  .edit-address-link{
    @extend a;
      border-radius: 0;
  }
}
.summary-items {
    .product.options{
        font-size: rem-calc(15);
        margin-bottom: 0.5rem;
        .toggle {
            cursor: pointer;
        }
    }
}
.validate-address-option {
    padding: 0;
    border: 0;
}

#paypal-fastlane-payment, paypal-card-selector {
  [type=number], [type=text], [type=expirationDate], [type=expirationMonth], [type=expirationYear], [type=cvv] {
    margin: 0;
    padding: 0;
    height: auto;
    box-shadow: none;
  }
}
.fastlane-label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
