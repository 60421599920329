.amazon-payment-selected {
    margin-bottom: 2rem;
    .icon {
        display: block;
        margin-bottom: 1rem;
    }
    .button.action {
        display: block;
        padding-left:0;
        padding-right: 0;
        width: 100%;
    }
}

@media screen and #{breakpoint(medium)} {
    .amazon-payment-selected {
        background: $light-gray;
        padding: 1rem;
        border-radius: $global-radius;
        .button.action {
            padding: $button-padding-large;
            &:hover, &:active, &:focus {
                box-shadow: $light-gray 0 0 0 0 inset;
            }
        }
    }
}

@media screen and #{breakpoint(large)} {
    .amazon-payment-selected {
        .icon {
            display: inline-block;
            margin: 0 0.5rem 0 0;
        }
        .button.action {
            width: auto;
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    .amazon-payment-selected {
        .button.action {
            display: inline-block;
            margin-left: 1.5rem;
        }
    }
}
