.amazon-checkout-button {
    display: inline-block;
}
.checkout-methods-items {
    .item {
        div.amazon-button-container {
            border-top: 1px dotted $ui-light-gray;
            border-bottom: 1px dotted $ui-light-gray;
            padding: 1.5rem 0 1rem;
            margin: 0 0 1.5rem;
            text-align: center;
        }
    }
}


.amazon-button-column {
    display: inline-block;
    vertical-align: top;
    .has-tip {
        margin-left: .5rem;
        border: 0;
    }
}
