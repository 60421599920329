%footer-bg {
    background: url('../images/pattern-bg/paper-bg_2e1e11.jpg') repeat #2e1e11;
    color: $white;
}

%footer-title {
    @extend .sec-font;
    font-size: rem-calc(20);
    font-weight: 300;
    color: get-color('light-green');
}

.footer {
    padding: 1rem;
    &.content {
        @extend %footer-bg;
    }
    &.policy {
        background: get-color('light-vanilla');
        padding: 0.5rem 1rem;
        p {
            margin: 0;
        }
    }
}

.copyright {
    margin: 1rem auto;
    .links {
        text-align: right;
        a {
            margin: 0 .5rem;
        }
    }
}

.footer-links {
    .fl-title {
        @extend %footer-title;
    }
    ul {
        margin: 0;
        li {
            margin: 1.5rem 0;
            &:first-child {
                margin-top: .5rem;
            }
        }
    }
    a {
        border: 0;
        font-weight: normal;
        color: $white;
        &:hover {
            color: darken($white, 20%);
        }
    }
    .accordion-item {
        border: 0;
    }
    .accordion-title {
        @extend %footer-title;
        &:before {
            content: url(../images/icons/arrow-down.svg);
        }
    }
    .accordion-item:not(.is-active) .accordion-title {
        color: get-color('light-green');
        &:before {
            content: url(../images/icons/arrow-down.svg);
            filter: brightness(100);
        }
    }
    .accordion-item.is-active .accordion-title {
         color: get-color('light-green');
         &:before {
             content: url(../images/icons/arrow-down.svg);
             filter: brightness(100);
         }
    }
}
.footer-title {
    @extend %footer-title;
}
.social-media {
    margin: 1.5rem 0;
    &-icons {
        font-size: rem-calc(22);
        color: #2e1e11;
        background: get-color(primary);
        padding: rem-calc(5);
        border-radius: 50%;
    }
    a{
        display: inline-block;
        &:focus {
            outline-color: transparent;
            background-color: rgba(get-color(primary), 0.25);
            box-shadow: 0 0 0 6px rgba(get-color(primary), 0.25);
        }
    }
}
.footer-co-info {
    margin-top: 2rem;
}

// Responsiveness

@media screen and #{breakpoint(medium)} {
    .footer {
        &.content {
            padding-top: 2rem;
            padding-bottom: 1rem;
        }
    }
    .footer-links {
        ul {
            li:first-child {
                margin-top: .75rem;
            }
        }
    }
    .footer-co-info {
        margin-top: 1rem;
    }
}

@media screen and #{breakpoint(large)} {
    .footer-links {
        margin-top: 1.5rem;
        .accordion-title {
            padding-bottom: 0;
        }
        .accordion-item:not(.is-active) .accordion-title {
            &:before {
                content: "";
            }
        }
        .accordion-item.is-active .accordion-title {
            color: get-color('light-green');
            &:before {
                content: "";
            }
        }
    }
    .footer-co-info {
        max-width: 30%;
        margin-top: 0.5rem;
        margin-left: 1.5rem;
        .v-card {
            margin-left: 0;
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    footer {
        margin-top: 3rem;
    }
    .footer-links {
        ul {
            li {
                margin: .5rem 0;
            }
        }
    }
    .copyright {
        margin: .5rem auto;
        .links {
            text-align: right;
        }
    }
}
