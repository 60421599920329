@use 'sass:math';

//Horizontal text divider
@mixin divider-txt($color, $bg-color, $content, $size, $margin) {
  $class-name: $content;
  @if ($class-name == 'and') {
    $content: '&';
  }
  .divider-#{$class-name} {
    text-align: center;
    margin: $margin;
    &:after {
      background: $bg-color;
      content: $content;
      color: $color;
      display: inline-block;
      font-size: $size;
      font-weight: $global-weight-extrabold;
      padding: 0 1rem;
      position: relative;
      text-transform: uppercase;
      top: math.div($size, -1.5);
    }
  }
}

//Horizontal icon divider
@mixin divider-icon($bg-color:'transparent', $icon-name: 'cmy-dots', $pos:'center') {
  $icon-width: 50px !default;
  $icon-height: 50px !default;
  $icon-top-pos: math.div($icon-height, -2) !default;
  @if ($icon-name =='bcmy-dots') {
    $icon-width: 20px;
    $icon-height: 20px;
    $icon-top-pos: math.div($icon-height, -2);
  } @else if ($icon-name =='cmy-dots') {
    $icon-width: 53px;
    $icon-height: 20px;
    $icon-top-pos: math.div($icon-height, -1.66);
  } @else if ($icon-name =='cmy-swash') {
    $icon-width: 50px;
    $icon-height: 36px;
    $icon-top-pos: math.div($icon-height, -4.5);
  }
  .divider-#{$icon-name} {
    text-align: center;
    position: relative;
    &:after {
      content: url('#{$svg-icons-path}#{$icon-name}.svg');
      @if ($bg-color !='transparent'){
        background: $bg-color;
      } @else{
        background-color: transparent;
      }
      padding: 0 1rem;
      display: inline-block;
      @if ($pos!='center') {
        #{$pos}: 0;
        padding-#{$pos}: 0;
      } @else {
        left: 50%;
        margin-left: math.div($icon-width, -2);
      }
      width: $icon-width;
      height: $icon-height;
      position: absolute;
      top: $icon-top-pos;
    }
  }
}

//Horizontal image divider
@mixin divider-image($bg-color, $image-name, $image-size) {
  .divider-image {
    border: 0;
    &.#{$image-name} {
      &:after {
        display: inline-block;
        content: '';
        width: 100%;
        height: $image-size;
        background: url('#{$svg-icons-path}#{$image-name}.svg') repeat-x center center transparent;
      }
    }
  }
}

//Vertical Text Divider
@mixin vertical-divider-txt($color, $bg-color, $content, $size, $height, $margin) {
  $class-name: $content;
  @if ($class-name == 'and') {
    $content: '&';
  }
  .divider-#{$class-name} {
    text-align: center;
    &.vertical {
      border-bottom: 0;
      border-right: $hr-border;
      min-height: $height;
      max-width: $size;
      display: inline-block;
      margin: $margin;
      &:after {
        background: $bg-color;
        content: $content;
        color: $color;
        display: inline-block;
        font-size: $size;
        font-weight: $global-weight-extrabold;
        padding: rem-calc(10) 0;
        position: relative;
        text-transform: uppercase;
        right: math.div($size, -2.5);
        top: (math.div($height, 2) - (math.div($size, 1.5)) + 1 );
      }
    }
  }
}
