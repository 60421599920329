.shipping-diff-block{
    margin: 3rem 0 1rem;
}

.fs-progress {
    background: get-color('tea-green');
    border-radius: 5000px;
    box-shadow: inset 0 1px 2px rgba($black, 0.25), 0 1px rgba($white, 0.08);
    position: relative;
    margin-bottom: 1rem;
    .bar {
        height: rem-calc(8);
        border-radius: 5000px;
        width: 0;
        transition: 0.6s linear;
        transition-property: width, background-color;
        background-color: get-color('light-green');
    }
    .shipping-icon {
        position: absolute;
        font-size: rem-calc(24);
        transition: left .6s linear;
        background: get-color('secondary');
        border-radius: 50%;
        padding: rem-calc(5);
        color: $white;
        bottom: rem-calc(20);
        left: 0;
        margin-left: rem-calc(-12);
        &:after {
            position: absolute;
            content: "";
            border-style: solid;
            border-width: 10px 8px 0 8px;
            border-color: get-color('secondary') transparent transparent transparent;
            left: 10px;
            bottom: -6px;
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .shipping-diff-block{
        margin-top: 0.5rem;
    }
}
