.has-tip {
    color: $has-tip-color;
    @include disable-mouse-outline;
    position: relative;
    display: inline-block;

    border-bottom: $has-tip-border-bottom;
    font-weight: $has-tip-font-weight;
    cursor: $has-tip-cursor;
    &.icon {
        border: 0;
        .tooltip-icon {
            margin: 0;
            font-size: rem-calc(20);
        }
    }
    &.ui-gray {
        color: $dark-gray;
    }
}

.tooltip {
    &.align-top::before {
        top: 1rem;
    }
    &.align-bottom::before {
        bottom: 1rem;
    }
    &.align-left::before {
        left: 1rem;
    }
    &.align-right::before {
        right: 1rem;
    }
}
