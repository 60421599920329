$steps-name : "Payment", "Shipping", "Review";
$steps-icon : "card-white", "truck-white", "truck-white";

.checkout-container{
  @include progress-bar($steps-name, $steps-icon, $clickable:false);
  .progress-bar{
    padding: 2rem 0 1rem;
    @include disable-mouse-outline;
    &-container{
      background: get-color('light-vanilla');
      padding: rem-calc(5 0 0);
    }
    > li {
      span {
          font-weight: 600;
        text-indent: 0;
      }
    }
  }
}


