@use 'sass:math';

@mixin progress-bar($steps-name, $steps-icon, $clickable:true, $icon-width: 30, $icon-height: 30, $txt-size: rem-calc(14), $svg-icon: true) {
    .progress-bar {
        display: table;
        table-layout: fixed;
        width: 100%;
        margin: 0 auto;
        background: transparent;
        > li {
            display: table-cell;
            text-align: center;
            position: relative;
            margin: 0;
            text-indent: 0;
            padding: 0;
            span {
                display: block;
                margin-top: rem-calc(math.div($icon-height, 2));
                font-size: $txt-size;
                vertical-align: top;
                > span {
                    font-size: rem-calc(14);
                    font-style: italic;
                    margin-top: rem-calc(8);
                    font-weight: 600;
                }
            }
            &:before {
                position: absolute;
                border-radius: 50%;
                width: rem-calc(math.div($icon-width, 2));
                height: rem-calc(math.div($icon-height, 2));
                content: "";
                display: block;
                text-align: center;
                background: $progress-background;
                top: 0;
                left: 50%;
                z-index: 1;
                $margin-top: (math.div($icon-width, -4)) - 6;
                $margin-left: math.div($icon-width, -4);
                margin: rem-calc($margin-top 0 0 $margin-left);
                box-shadow: inset 0 1px 2px rgba($black, 0.25), 0 1px rgba($white, 0.08);
            }
            &:after {
                position: absolute;
                width: 100%;
                height: $progress-height;
                content: "";
                display: block;
                background: $progress-background;
                box-shadow: inset 0 1px 2px rgba($black, 0.25), 0 1px rgba($white, 0.08);
                transform: translateY(-100%);
                left: 50%;
                top: 0;
                z-index: 0;
                margin-top: math.div($progress-height, -2);
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            &.complete {
                @if ($clickable == true) {
                    cursor: pointer;
                    &:hover {
                        &:before {
                            background-color: $progress-complete-bg;
                        }
                    }
                }
                &:after {
                    background: $progress-complete-bg;
                }
            }
            &.complete, &.current {
                &:before {
                    background: $progress-complete-bg;
                    color: $white;
                    line-height: 1;
                    padding: rem-calc(math.div($icon-width, 4));
                    width: rem-calc($icon-width);
                    height: rem-calc($icon-height);
                    $complete-margin-top: math.div($icon-height, -2) - 6;
                    $complete-margin-left: math.div($icon-width, -2) - 4;
                    margin-top: rem-calc($complete-margin-top);
                    margin-left: rem-calc($complete-margin-left);
                }
                @for $i from 1 through length($steps-name) {
                    $current-step: nth($steps-name, $i);
                    $current-icon: nth($steps-icon, $i);
                    @if ($svg-icon) {
                        &[data-step=#{$current-step}]:before {
                            content: url('#{$svg-icons-path}#{$current-icon}.svg');
                        }
                    } @else {
                        &[data-step=#{$current-step}]:before {
                            font-family: '#{$icomoon-font-family}' !important;
                            content: $current-icon;
                            padding: rem-calc(7 3.5);
                        }
                    }
                }
            }
        }
    }

}
