.timer-block {
    background: get-color('blue');
    .timer-wrapper {
        background: transparent;
        padding: 0.75rem 0 1rem;
        text-align: center;
        .headliner {
            display: block;
            color: $white;
            font-family: $work-sans;
            font-size: rem-calc(20);
        }
        .t-box, .t-sep {
            color: $white;
            font-weight: 700;
            display: inline-block;
            vertical-align: top;
            text-align: center;
        }
        .t-var, .t-sep {
            line-height: 1;
            margin: rem-calc(0 2);
            font-family: $work-sans;
            font-size: rem-calc(28);
        }
        .t-var {
            color: get-color('light-yellow');
            min-width: rem-calc(42);
            display: inline-block;
        }
        .t-tag {
            line-height: 1;
        }
        .code {
            color: get-color('light-yellow');
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .timer-block {
        .timer-wrapper {
            .headliner, .timer{
                display: inline-block;
                vertical-align: top;
            }
        }
    }
}
@media screen and #{breakpoint(large)} {
    .timer-block {
        .timer-wrapper {
            .headliner{
                font-size: rem-calc(24);
            }
            .t-var{
                font-size: rem-calc(32);
            }
        }
    }
}
