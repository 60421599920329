@import '../../styles/settings';
@import '../../styles/cas/mixins';
@import 'checkout/shipping-diff';
@import '../../Amazon_Pay/styles/cart';

@include accordion-panel($panel-color: 'ice', $padding: 1rem);

.top-brands {
    .menu {
        li {
            margin: 0 .5rem;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.cart-header {
    &-left {
        padding-right: 2rem;
    }
    &-right {
        .action-box {
            margin: .75rem .5rem 1rem;
        }
    }
}
.est-delivery {
    margin: 0 0 1.5rem;
}

.checkout-cart-index {
    .cart-summary {
        @include bordered-box;
        margin-top: .5rem;
        padding: 1rem;
    }
    .messages {
        .callout {
            margin-top: 0;
        }
    }
}

%col-qty {
    text-align: right;
}

.cart.item {
    border-bottom: 1px solid $light-gray;
    padding: 1rem 0;
    .product-image-container, .product-image-photo {
        max-width: 60px;
        max-height: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .subtotal {
        font-weight: bold;
    }
    .action-delete {
        color: $medium-gray;
        &:hover {
            color: $ui-gray;
        }
    }
    .action.update {
        margin-top: 1rem;
    }
    .col {
        padding-left: .25rem;
        padding-right: .25rem;
        &.item {
            width: 12%;
        }
        &.detail {
            width: 60%;
        }
        &.qty {
            text-align: right;
            .field {
                &.qty {
                    @extend %col-qty;
                    .qty-select {
                        margin-top: 0;
                    }
                }
                &.subtotal {
                    @extend %col-qty;
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .item-options {
        .accordion-title {
            color: $medium-gray;
            font-size: 1rem;
            padding-top: .5rem;
            font-style: italic;
            &:before {
                top: 39%;
                left: 6.25rem;
                right: initial;
            }
        }
        span.price {
            display: none;
        }
        .accordion-content {
            font-size: rem-calc(15);
        }
    }
}

.form-cart {
    .main.actions {
        .button {
            margin-top: .5rem;
            margin-left: 2rem;
        }
    }
}

.cart.savings-block {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    padding-left: .5rem;
    border-bottom: $global-border;
}

.shipping {
    &-method {
        font-weight: bold;
    }
}

.checkout-methods-items {
    text-align: center;
    input {
        padding-left: 0;
    }
    .item {
        margin: 1rem auto 2rem;
        &:nth-child(2) {
            border-top: 1px dotted $ui-light-gray;
            padding-top: 1rem;
            margin-bottom: 1rem;
        }
        > div {
            margin: .75rem 0;
        }
    }
}

.paypal.after.paypal-logo {
    a {
        display: none;
    }
}

.address-warning {
    font-size: rem-calc(15);
}

@include callout(alert);
@include callout(error);
@include callout(success);
@include callout(warning);

@include cas-btn('cyan', 'cyan', $btn-type: 'solid');
@include cas-btn('orange', 'orange', $btn-type: 'hollow');

@include divider-txt($ui-gray, $off-white, 'or', rem-calc(17), 2.5rem auto);

.shipping-calc {
    border-radius: $global-radius;
    .accordion-title {
        font-size: 1rem;
        padding: rem-calc(14) 0;
        &:before {
            right: 0;
        }
    }
    .accordion-content {
        background: lighten(get-color('vanilla'), 1%);
        border-radius: $global-radius;
        padding: 0.5rem 1rem 1rem;
    }
    input, select {
        border: 1px solid $ui-light-gray;
    }
    fieldset.rate {
        border: 1px solid darken(get-color('vanilla'), 8%);
        padding: 0.5rem 1rem;
        legend {
            margin-bottom: 0;
        }
        .methods {
            margin-bottom: 0;
        }
    }
}

.cart-sub-box {
    border-bottom: $global-border;
    padding: 0.5rem 0.5rem 1rem;
    margin: 0 0 1rem;
    .sub-icon {
        margin-right: 0.25rem;
        color: $ui-gray;
    }
}
@include neut-btn('ui-gray', $ui-gray, 'flat');
.coupon-block {
    margin: 0 0 1rem;
    .accordion-content {
        padding: 0;
    }
    .coupon-form-box {
        padding: 1rem;
        background: lighten(get-color('vanilla'), 1%);
    }
    .accordion-title {
        font-size: 1rem;
        &:before {
            right: 0;
        }
    }
    .form-inline {
        input[type="text"] {
            width: 100%;
            margin: 0;
        }
        .button.apply {
            padding-left: 0;
            padding-right: 0;
            margin: 0;
        }
        .button.remove {
            padding: 0;
            margin-top: -5px;
            .svg-icons {
                fill: $ui-light-gray;
            }
            &:hover {
                .svg-icons {
                    fill: $ui-gray;
                }
            }
        }
    }
    .coupon-applied {
        border: 2px dotted $ui-light-gray;
        padding: .5rem;
    }
}

.shipping-estimate-form {
    .field {
        margin: 1rem 0;
    }
    select {
        margin-bottom: .5rem;
    }
    [type="text"].input-text {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
}
.form-cart {
    margin-bottom: 2rem;
}

fieldset.rate {
    margin-bottom: 0;
}

.shipping-eta {
    display: block;
    line-height: 1.4;
}

.summary-wrapper {
    margin: 1rem 0;
    padding: 0;
    .summary-details {
        margin-bottom: 2rem;
        .summary-row {
            font-size: rem-calc(17);
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .grand-total {
            border-top: $global-border;
            font-size: rem-calc(20);
            font-weight: 700;
            padding-top: .75rem;
        }
        .discount-amount {
            color: get-color('green');
            font-weight: 700;
        }
        .shipping {
            &-row {
                border-top: $global-border;
                padding-top: 1rem;
            }
        }
    }
}

.help-card.cart-page {
    text-align: left;
    .help-card {
        &-item {
            margin: 0;
            border: $global-border;
            border-radius: $global-radius;
            text-align: center;
            padding: .25rem 0;
            cursor: pointer;
            &:hover {
                background: rgba(get-color(primary), 0.1);
                border-color: lighten(get-color(primary), 15%);
            }
        }
        &-icons {
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5rem;
            font-size: 1.2rem;
        }
        &-link {
            color: $dark-gray;
            background: none;
        }
    }
}

.url-list {
    li {
        margin: .5rem 0;
    }
}
.modal-form {
    .subscribe {
        display: block;
        input, .button {
            width: 100%;
        }
        .button {
            margin-top: 1rem;
        }
    }
}

.checkout-modal-wrapper{
    .guest-column{
        margin-top: 2rem;
        border-top: $global-border;
        padding-top: 1rem;
    }
}

@media screen and #{breakpoint(medium)} {
    .modal-form {
        .subscribe {
            display: block;
            input, .button {
                display: inline-block;
                vertical-align: middle;
            }
            input {
                width: 60%;
                margin-right:0;
            }
            .button {
                margin: 0 0 0 0.5rem;
                width: calc(40% - 1em);
            }
        }
    }
    .checkout-modal {
         .guest-column{
             margin-top: 0;
             border-top: 0;
             padding-top: 0;
         }
    }
}

@media screen and #{breakpoint(large)} {
    .modal-popup.checkout-modal-wrapper .modal-inner-wrap {
        width: 70%;
        padding: 3rem 0;
    }
    .cart-header {
        &-right {
            .action-box {
                margin-bottom: 0;
            }
        }
    }
    .cart.item {
        .col {
            &.detail {
                width: 55%;
            }
            &.qty {
                .field {
                    &.qty, &.subtotal {
                        text-align: right;
                    }
                    .qty-select {
                        width: auto;
                    }
                }
            }
        }
    }
    .form-cart {
        margin-bottom: 4rem;
    }
    .checkout-cart-index {
        .cart-summary {
            padding: 1rem 2rem;
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    .cart.item {
        .action.update {
            margin-top: 0;
            margin-left: 0.5rem;
        }
    }
    .modal-popup.checkout-modal-wrapper .modal-inner-wrap {
         width: 50%;
    }
}
