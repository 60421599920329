.sp-icons{
  margin-right: .5rem;
  color: $ui-light-gray;
  font-size: rem-calc(30);
}
.selling-points {
  &-text {
    font-size: rem-calc(15);
    vertical-align: top;
      .sub-link {
          color: $dark-gray;
          border-bottom-color: darken($ui-light-gray, 8%);
    }
  }
  a{
    vertical-align: top;
  }

}

@media screen and #{breakpoint(medium)} {
  .selling-points{
    padding: 0 rem-calc(10);
  }
}

@media screen and #{breakpoint(large)} {
  .selling-points{
    margin-top: 1rem;
  }
  .section-title{
    font-size: rem-calc(24);
  }
  .selling-points {
    &-text {
      font-size: rem-calc(17);
    }
  }
}
