%center-dropmenu {
    right: initial;
    left: 50%;
}

.dropdown.menu {
    margin: 0 auto;
    > li {
        border-top: 3px solid transparent;
        padding: rem-calc(11 17);
        > a {
            color: $dark-gray;
            .menu-caret {
                color: $medium-gray;
            }
            &:focus {
                outline: 2px solid transparent;
                background-color: get-color(primary);
                box-shadow: 0 0 0 6px get-color(primary);
                color: $white;
            }
        }
        &.has-dropdown-submenu {
            &:hover {
                background: $white;
                border-top: 3px solid get-color(secondary);
                > a {
                    color: get-color('dark-yellow');
                }
            }
            > a {
                &:after {
                    margin-top: -1px;
                }
            }
            &.is-active {
                &:hover {
                    > a {
                        color: $medium-gray;
                        .menu-caret {
                            color: $medium-gray;
                        }
                    }
                }
            }
        }
    }
    .is-dropdown-submenu {
        box-shadow: 1px 10px 10px 0 rgba(get-color('midnight'), 0.35);
        border-top-width: 5px;
        border-bottom-width: 5px;
    }
}

.nav-sections {
    background: url('../images/pattern-bg/paper-bg_31590b.jpg') repeat-x #31590b;
    border-bottom: 5px solid #73bf44;
    .dropdown.menu {
        max-width: map-get($breakpoints, 'xlarge');
        justify-content: space-around;
        > li {
            border-top: 3px solid transparent;
            padding: rem-calc(11 17);
            > a {
                font-size: rem-calc(17);
                padding-bottom: rem-calc(2);
                color: $white;
                background: none;
                outline-color: transparent;
                .menu-caret {
                    color: $white;
                }
                .cas-i-coupon {
                    font-size: 1rem;
                }
                &:hover {
                    color: get-color('dark-yellow');
                    .menu-caret {
                        color: get-color('dark-yellow');
                    }
                }
                &:focus {
                    outline-color: transparent;
                    background-color: get-color(primary);
                }
            }
        }
        .is-dropdown-submenu {
            border-top: 0;
            padding: 1.5rem;
            > li {
                margin: 0.5rem;
                vertical-align: top;
                a {
                    padding: 0 0 2px 0;
                    display: inline-block;
                }
            }
            &.other-brand {
                min-width: rem-calc(520);
                @extend %center-dropmenu;
                margin-left: rem-calc(-260);
                > li {
                    width: calc(100% /3 - 1.25rem);
                    display: inline-block;
                }
            }
            &.accs-nav {
                @extend %center-dropmenu;
                margin-left: rem-calc(-150);
            }
        }
    }
}

.top-account-menu {
    .dropdown.menu {
        align-items: flex-end;
        >li {
            padding: rem-calc(11 17);
            > a{
                background: none;
                outline-color: transparent;
                &:focus {
                    outline: 2px solid transparent;
                    background-color: get-color(secondary);
                    box-shadow: 0 0 0 6px get-color(secondary);
                    .cas-i-cart {
                        color: $white;
                    }
                }
            }
            &.top-menu-link {
                box-shadow: none;
                > a {
                    &:hover {
                        color: get-color(secondary);
                    }
                }
            }
            > a {
                .cas-i-cart {
                    margin-right: rem-calc(7);
                }
            }
        }
        .has-dropdown-submenu {
            border: 0;
            &:hover {
                border: 0;
                background-color: get-color(secondary);
                box-shadow: 0px 3px 2px 0px $white, 0px 0 2px 0px rgba(get-color('midnight'), 0.35);
                border-radius: $global-radius $global-radius 0 0;
                > a {
                    color: $white !important;
                    &:after {
                        border-color: $white transparent transparent;
                    }
                }
                .cas-i-cart {
                    color: $white;
                }
            }
        }
        .is-dropdown-submenu {
            border-bottom-width: 1px;
            border-color: get-color(secondary);
            border-radius: 0 0 $global-radius $global-radius;
            &.account-menu{
                right: initial;
                left: 0;
            }
            &.help-menu {
                @extend %center-dropmenu;
                margin-left: rem-calc(-150);
            }
            &.minicart {
                padding: 0 0 1rem;
                min-width: rem-calc(420);
                box-shadow: 0 10px 10px 0 rgba(get-color('midnight'), 0.35);
            }
        }
    }
}

.minicart-items-wrapper {
    height: auto !important;
    max-height: 450px;
    overflow-y: auto;
    -ms-overflow-y: auto;
}
