// Custom Helpers for CAS
// - - - - - - - - - - - - - - -
// These are custom helpers applied to basic HTML tags, including:
//   - Work Sans font
//   - Ordered and unordered cmy-bullet
//   - External link, anchor link and link over dark-bg

/// Default value for the custom links
$cas-dkbg-links-border-bottom: 2px dotted mix($light-gray, $medium-gray, 50%) !default;

.work-sans, .sec-font {
  font-family: $sec-font;
}

.semibold-txt {
  font-weight: 600;
}

.lh-1{
  line-height: 1;
}

.lh-1_2{
  line-height: 1.2;
}

.lh-1_4{
  line-height: 1.4;
}
.lh-1_5{
  line-height: 1.5;
}
.fs-15{
  font-size: rem-calc(15);
}
.fs-20{
  font-size: rem-calc(20);
}
.green-txt{
  color: get-color('green');
}
.white-link{
  color: $white;
  &:hover{
    color: $light-gray;
  }
}
.cyan-link{
  color: get-color('cyan');
  &:hover{
    color: $cyan-hover;
  }
}
.lt-cyan-link{
     color: lighten(get-color('cyan'), 5%);
     &:hover{
         color: $cyan-hover;
     }
}
.div-title {
  margin-top: 0;
}
.tiny-txt {
  font-size: rem-calc(11);
  line-height: 1.2;
}


.link {
    display: inline;
    color: $anchor-color;
    background: linear-gradient(0deg, get-color(primary), get-color(primary)) no-repeat left 100%;
    line-height: $cas-links-line-height;
    font-weight: $cas-links-font-weight;
    background-size: 0 2px;
    transition: all 0.35s;
    padding-bottom: rem-calc(2);
    border-radius: 0;
    cursor: pointer;
    &:hover {
        background-size: 100% 2px;
        background-position-x: left;
        text-decoration: none;
    }
    &:focus {
        outline: 2px dotted $ui-gray;
    }
    &.dark-link {
        color: $dark-gray;
        background: linear-gradient(0deg, $dark-gray, $dark-gray) no-repeat left 100%;
        background-size: 0 2px;
        &:hover {
            color: $medium-gray;
        }
    }
    &.darkbg-link {
         color: lighten(get-color(primary), 15%);
        background: linear-gradient(0deg, lighten(get-color(primary), 15%), lighten(get-color(primary), 15%)) no-repeat left 100%;
        background-size: 0 2px;
         &:hover {
             background-size: 100% 2px;
             background-position-x: left;
             color: get-color(primary);
         }
     }
}


a {
  &.plain {
      border: 0;
      color: $dark-gray;
      font-weight: normal;
  }
  &.underline-link{
    @extend %underline-links;
  }
  &.anchor-link, &.ext-link {
    margin-right: 1rem;
    &:after {
      position: absolute;
      width: 12px;
      height: 12px;
      margin-left: rem-calc(5);
      border-bottom: none;
    }
  }
  &.anchor-link {
    &:after {
      content: url('#{$svg-icons-path}chain.svg');
    }
  }
  &.ext-link {
    &:after {
      content: url('#{$svg-icons-path}external.svg');
    }
  }
  &.darkbg-link {
      color: lighten(get-color(primary), 15%);
    border-bottom: $cas-dkbg-links-border-bottom;
      &:hover {
          color: get-color(primary);
      }
  }
  &.dark-link {
    color: $dark-gray;
    &:hover {
        color: lighten($dark-gray, 10%);
    }
  }
  &.gray-link {
    color: $medium-gray;
    &:hover {
      color: lighten($medium-gray, 10%);
    }
  }
}

.btn-link {
    color: $anchor-color;
    line-height: $cas-links-line-height;
    font-weight: $cas-links-font-weight;
    border-bottom: 2px solid transparent;
    &:hover, &:focus {
        color: $anchor-color-hover;
        border-bottom: $cas-links-border-bottom;
    }
}

.valign-middle {
  vertical-align: middle;
}

.valign-top {
  vertical-align: top;
}

.valign-bottom {
  vertical-align: bottom;
}

.small-expanded{
  width: 100%;
}

.width-auto{
  width: auto;
}
.green-icon{
  fill: get-color('green');
  padding-right: .15rem;
}
.img-responsive {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  height: auto;
}

@media screen and #{breakpoint(medium)}{
  .small-expanded{
    width: initial;
  }
}

.fotorama-focus,
.fotorama__nav__frame,
.fotorama__fullscreen-icon:focus,
.fotorama__zoom-out:focus,
.fotorama__zoom-in:focus {
    outline: 2px dotted transparent;
}

.text-line-through {
    text-decoration: line-through;
}

.font-normal {
    font-weight: normal;
}

.font-bold {
    font-weight: bold;
}

.font-italic {
    font-style: italic;
}

.bordered {
    border: $global-border;
}

.border-none {
    border: none !important;
}

.limiter-label {
    display: inline-block;
}
.select-options {
    margin: 0;
    width: auto;
}

.g-recaptcha {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
