select {
    appearance: none;
    height: rem-calc(45);
    display: inline-block;
    border: $input-border;
    border-radius: $select-radius;
    background-color: $select-background;
    box-shadow: $select-shadow;
    padding: 0 $input-padding *2 0 $input-padding;
    line-height: 2;
    color: $input-color;
    margin-top: 0.5rem;
    @include background-triangle($select-triangle-color);
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: rem-calc(14px 7px);
    background-position: $global-right (-$input-padding) center;
    &:focus {
        outline: 2px solid transparent;
        border: $input-border-focus;
        box-shadow: $input-shadow-focus;
    }
}
@include file-uploader;
