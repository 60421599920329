@import '../../vendor/icomoon/variables/variables';

.button {
    -webkit-appearance: none;
    display: inline-block;
    vertical-align: middle;
    border-radius: $button-radius;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    font-size: map-get($button-sizes, default);
    background-color: $button-background;
    color: $button-color;
    padding: $button-padding;
    position: relative;
    transition: $button-transition;
    text-shadow: $global-text-shadow;
    border: 2px solid $button-background;
    &:before {
        font-family: $icomoon-font-family;
        content: $cas-i-logo-icon;
        color: $button-background-hover;
        font-size: rem-calc(22);
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 15%;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: $button-transition;
        text-shadow: none;
        outline-color: transparent;
    }
    &:hover, &:focus, &:active {
        outline-color: transparent;
        background-color: $button-background-hover;
        color: $button-color;
        border-color: $button-background-hover;
        &:before {
            transform: translateY(-31px);
            opacity: 1;
        }
    }
    &:focus {
        box-shadow: inset 0 0 0 2px $white;
    }
    &.disabled {
        background: $light-gray;
        border-color: $light-gray;
        color: lighten($medium-gray, 5%);
        box-shadow: none;
        cursor: not-allowed;
        text-shadow: none;
        &:hover, &:active, &:focus {
            @extend .disabled;
        }
    }
    &.expanded {
        width: 100%;
    }
    &.hollow {
        @include transition($button-hollow-transition);
        border: 2px solid;
        background-color: transparent;
        color: $button-background;
        transition: $button-hollow-transition;
        text-shadow: none;
        &:before {
            color: $button-background;
        }
        &:hover, &:active, &:focus {
            color: $button-hollow-hover-color;
            border-color: $button-hollow-hover-color;
            &:before {
                color: $button-hollow-hover-color;
            }
        }
        &:focus {
            box-shadow: none;
            background-color: rgba($button-hollow-hover-color, 0.15);
        }
    }
    &.flat {
        border: 0;
        background: transparent;
        box-shadow: none;
        text-shadow: none;
        &:before {
            display: none;
        }
        &:focus {
            outline: 2px dotted;
        }
    }
    &.small-expanded {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    > * {
        line-height: 1;
    }
}


a.button {
    background-image: none;
}

@media screen and #{breakpoint(medium)}{
    .button {
        padding: $button-padding-large;
        &.small-expanded {
            width: auto;
            padding: $button-padding-large;
        }
    }
}

@include neut-btn('ui-gray', $ui-gray, $btn-type: 'hollow');
@include neut-btn('medium-gray', $medium-gray, $btn-type: 'hollow');
@include neut-btn('dark-gray', $dark-gray, $btn-type: 'hollow');
