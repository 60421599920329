%minicartPadding {
    padding-left: 2rem;
    padding-right: 2rem;
}

%smallFont {
    font-size: $small-font-size;
    line-height: 1.2;
}

.empty-cart {
    padding: 2.5rem 2rem 2rem;
    text-align: center;
    font-size: rem-calc(19);
    .icon {
        display: block;
        font-size: rem-calc(50);
        margin-bottom: 0.5rem;
        color: $ui-light-gray;
    }
}

.minicart {
    &-total {
        @extend %minicartPadding;
        margin: 1rem 0;
        font-size: rem-calc(19);
        font-weight: 700;
        &-item {
            font-size: 1rem;
            font-weight: 600;
        }
    }
    &-shipping {
        text-align: center;
        background-color: get-color('secondary');
        color: $white;
        padding: .35rem 2rem 0.65rem;
        margin: 0 0 1rem;
        .truck-icon {
            vertical-align: middle;
            margin-right: .5rem;
            max-width: 1rem;
        }
        .trashcan-icon {
            vertical-align: middle;
            min-width: 1rem;
        }
    }
    &-content {
        @extend %minicartPadding;
        position: relative;
        &-count {
            position: absolute;
            bottom: 1rem;
            right: 0;
            border-radius: 5000px;
            padding: 0 .35rem;
            line-height: 1.5;
            text-align: center;
            font-size: rem-calc(13);
            background: get-color('orange');
            color: $white;
        }
    }
    &-action {
        @extend %minicartPadding;
        margin: 1.5rem 0;
    }
    &-price {
        font-weight: 600;
    }
}
.dropdown {
    .minicart-action .button {
        padding: $button-padding-large;
        color: $white;
    }
}
.minicart-item {
    padding: 1rem 0;
    border-bottom: 1px solid $light-gray;
    &.last {
        border-bottom: 0;
    }
    &-img {
        img {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: rem-calc(25);
            max-height: rem-calc(25);
            margin-right: 1rem;
        }
    }
    &-title {
        @extend %smallFont;
        a.title, .title {
            display: inline-block;
            margin-bottom: rem-calc(10);
            font-weight: 400;
            line-height: 1.2;
        }
    }
    &-qty {
        width: 4rem;
        margin-left: rem-calc(5);
        @extend %smallFont;
        height: rem-calc(40);
    }
}

@media screen and #{breakpoint(large)} {
    .minicart {
        &-content {
            &-count {
                right: initial;
                left: 1.5rem;
            }
        }
    }
}
