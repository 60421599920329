.form {
  &-wrapper {
    background: get-color('ice');
    padding: $form-spacing;
  }
  &-title {
    margin-bottom: 0;
    @extend .work-sans;
  }
  &-subtitle {
    margin-top: .25rem;
    margin-bottom: 1.25rem;
  }
  &-button {
    margin-top: 2rem;
  }
  &-extra-info {
    margin-top: 1rem;
  }
  &-inline {
    label{
      display: inline-block;
    }
    .input-label {
      display: block;
    }
    input {
      width: auto;
    }
    input[type="submit"], .form-button {
      margin: 0 0 0 .5rem;
      vertical-align: baseline;
    }
  }
}

.pill-form {
    display: flex;
    input {
        flex: 1 1 0px;
        width: auto;
        max-width: 100%;
        border-radius: $global-radius;
        margin: 0 2px 0 0;
        padding: rem-calc(13 20 15);
    }
    .button {
        margin: 0 0 0 0.5rem;
        padding: rem-calc(12 20);
        span {
            line-height: 1;
        }
        &.icon {
            color: $black;
            padding: rem-calc(11 20);
        }
        &:hover {
            &:before {
                display: none;
            }
        }
        &:focus {
            outline: 2px solid transparent;
            box-shadow:  0px 0px 0 2px $white, 0px 0px 4px 4px lighten($button-background, 5%);
        }
    }
}
.field.captcha {
    .label {
        display: block;
        font-size: 1rem;
        padding: 0;
        line-height: 1.2;
        color: $dark-gray;
        background: transparent;
    }
}
.captcha-reload {
    @extend .btn-link;
    margin-top: 1rem;
    margin-left: 1rem;
    @media screen and #{breakpoint(medium)} {
        margin-top: 0;
    }
}

@import 'checkbox';
@import 'error';
@import 'fieldset';
@import 'help-text';
@import 'input-group';
@import 'select';
@import 'label';
@import 'text';
