input {
    -webkit-appearance: none;
    font-size: 1rem;
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], [type='expirationDate'], [type='expirationMonth'], [type='expirationYear'], [type='cvv'], textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: rem-calc(45);
    margin: 0.25rem 0 $form-spacing;
    padding: $input-padding;
    border-radius: $input-radius;
    border: $input-border;
    background-color: $input-background;
    box-shadow: $input-shadow;
    &:focus {
        outline: 2px solid transparent;
        border: $input-border-focus;
        background:$input-background-focus;
        box-shadow: $input-shadow-focus;
    }
}

// Text areas
textarea {
    max-width: 100%;

    &[rows] {
        height: auto;
    }
}

input, textarea {
    &::-webkit-input-placeholder {
        font-size: rem-calc(15);
    }
    &:disabled,
    &[readonly] {
        background-color: $input-background-disabled;
        cursor: $input-cursor-disabled;
    }
}
input [type='submit'],
input [type='button'] {
    appearance: none;
}

input[type='search'] { // sass-lint:disable-line no-qualifying-elements
    box-sizing: border-box;
}


[type='number'] {
    @if not $input-number-spinners {
        -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefix

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefix
            margin: 0;
        }
    }
}
.input-required {
    display: inline;
    color: darken(get-color(alert), 15%);
}
