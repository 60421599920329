
.checkout-page {
    .step-container {
        &.review {
            .shipping-information {
                border: 0;
                padding: 0;
            }
            .ship-via {
                border-top: $global-border;
                padding-top: 1rem;
                margin-top: 1rem;
                .value {
                    font-weight: 700;
                }
            }
        }
    }
}

.step-title-label {
    .step-title {
        &.payment {
            &:before {
                content: "2.";
                display: inline-block;
                margin-right: 0.5rem;
            }
        }
    }
}
