%font-size-15{
  font-size: rem-calc(15);
  line-height: 1.2;
}

.steps-container {
  .summary-block{
    @extend %checkout-panel;
    padding: 0.5rem 1rem;
    margin-top: 2rem;
      img {
          overflow: hidden;
          text-overflow: ellipsis;
      }
  }

  .checkout-summary{
    .accordion-title{
      &:before{
        width: 17px;
        height: 11px;
      }
    }
    .summary-title{
      font-size: rem-calc(20);
    }
    .summary-nums{
      font-size: rem-calc(15);
      font-style: italic;
      font-weight: 600;
    }
    .minicart-item-inner-name-block{
      margin-bottom: .5rem;
    }
    .product-item-name{
      @extend %font-size-15;
    }
    .cart-price{
      @extend %font-size-15;
      font-weight: 700;
      vertical-align: text-bottom;
    }
    .product-item-qty{
      @extend %font-size-15;
    }
  }
  .summary-block{
    tbody th, tbody td{
      padding: 0.5rem 0;
    }
    tbody tr:nth-child(even){
      background: none;
    }
    tbody tr:nth-last-child(2){
      th, td{
        padding-bottom: 1.25rem;
      }
    }
    .accordion-item{
      border-bottom: 0;
      margin-bottom: .75rem;
    }
    .shipping {
      .label {
        background: none;
        padding: 0;
        font-size: 1rem;
        color: $dark-gray;
        line-height: 1.4;
      }
      .value{
        font-style: italic;
        font-weight: normal;
      }
    }
    .grand.totals{
      border-top: $global-border;
      font-size: rem-calc(20);
      td, th {
        padding-top: 1rem;
      }
    }
    .amount{
      text-align: right;
    }
  }
}
.aw-giftcard-totals, .totals.store-credit {
  .action-delete.button-link{
    @extend a;
    font-size: $small-font-size;
    color: $ui-gray;
    margin-top: .35rem;
    &:hover{
      color: lighten(get-color('orange'), 10%);
    }
  }
}
