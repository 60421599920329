.page-footer {
    .newsletter-heading {
        font-size: rem-calc(20);
        @extend .sec-font;
        font-weight: 700;
        display: block;
        margin: 0 0 0.5rem;
    }
    .form.subscribe {
        margin-bottom: 0;
    }
    .newsletter {
        background: url('../images/pattern-bg/paper-bg_559a13.jpg') repeat #559a13;
        padding: 1rem;
        margin: 0.5rem 0;
        .subscribe {
            input {
                margin-top: 0;
                margin-bottom: 0;
            }
            .button {
                margin: 1rem 0 0.5rem;
                width: 100%;
                box-shadow: 3px 3px 3px rgba($black, 0.4);
            }
        }
        .policy {
            margin: rem-calc(5 0 0);
        }
    }
    .sub-policy {
        @extend .white-link;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .page-footer {
        .newsletter {
            .subscribe {
                input, .button {
                    display: inline-block;
                    width: 50%;
                    vertical-align: middle;
                }
                .button {
                    width: calc(50% - 1rem);
                    margin: 0 0 0 0.5rem;
                    padding: rem-calc(12);
                }
            }
        }
    }
}

@media screen and #{breakpoint(large)} {
    .page-footer {
        .newsletter-heading {
            font-size: rem-calc(28);
        }
    }
}
