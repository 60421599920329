.menu {
    display: flex;
    flex-wrap: wrap;
    li {
        padding-left: 0;
        text-indent: 0;
        &:before {
            display: none;
        }
        &.is-submenu-item {
            > a {
                color: $medium-gray;
                font-weight: $global-weight-normal;
            }
            &.is-accordion-submenu-parent {
                > a {
                    font-weight: $global-weight-bold;
                }
            }
        }
        &[aria-expanded="true"] {
            > a {
                color: get-color(primary);
            }
        }
    }
    a {
        color: $medium-gray;
        font-weight: $global-weight-bold;
        border-bottom: 2px solid transparent;
        padding: rem-calc(10 0);
        margin: .5rem 1rem;
        &:hover {
            color: get-color(primary);
            border-bottom: 2px solid get-color(primary);
        }
    }
    &.vertical {
        a {
            border: 0;
            @include disable-mouse-outline;
            padding: $menu-items-padding;
            @media screen and (min-width: 64em) {
                padding: $menu-items-padding-large;
            }
            margin: 0;
        }
    }
}
.is-dropdown-submenu {
    display: none;
}
.accordion-menu {
    .is-accordion-submenu-parent {
        &:not(.has-submenu-toggle) > a::after {
            border: 0;
            content: url('#{$svg-icons-path}arrow-down.svg');
            width: 10px;
            margin-top: -10px;
        }
        &[aria-expanded="true"] > a::after {
            margin-top: 12px;
        }
    }
}
