@import "../../../Magento_Search/styles/header-searchbar";

.top-header {
    background: get-color('light-vanilla');
    border-bottom: 1px solid darken(get-color('light-vanilla'), 5%);
    padding: .25rem 0;
    .help-section {
        a {
            border-bottom: 2px dotted $ui-light-gray;
        }
    }
}

.mobile-menu-icon {
    color: $dark-gray;
    font-size: rem-calc(22);
}

.logo {
    background: none;
    &-wrapper {
        min-width: rem-calc(140);
    }
    img {
        max-width: 150px;
    }
    display: block;
    margin: 0 0 0 .5rem;
    cursor: pointer;
}

.main-header {
    padding: 1rem 0;
    position: relative;
    box-shadow: $global-box-shadow;
    .welcome {
        line-height: 1.2;
        margin-bottom: 0;
        .hello {
            font-weight: 400;
            color: $medium-gray;
        }
    }
    ul.account-menu {
        min-width: 280px;
        text-align: center;
        > li.is-submenu-item {
            a {
                &.account-links {
                    background: none;
                    font-weight: 700;
                    display: block;
                }
            }
            .button {
                width: calc(100% - 4rem);
                color: $white;
                padding: $button-padding-large;
                font-weight: 600;
                &.login-btn {
                    margin: 0 2rem 0.5rem;
                }
                &.register-link {
                    margin: 1rem 2rem;
                    color: $medium-gray;
                }
                &.logout-btn {
                    width: 100%;
                }
            }
        }
        .welcome-back {
            font-size: rem-calc(24);
            @extend .work-sans;
            font-weight: 700;
            margin-top: 1rem;
            display: block;
        }
    }
    .account-login {
        padding-top: 1rem;
        background: get-color('light-vanilla');
    }
    .account-register {
        background: get-color('light-vanilla');
        border-bottom: 1px solid darken(get-color('light-vanilla'), 5%);
        padding: 0.5rem 0;
    }
    .account-menu-box {
        cursor: pointer;
        text-align: left;
        padding: rem-calc(12 17);
        &:hover {
            background: darken(get-color('light-vanilla'), 2%);
        }
        .account-links {
            font-weight: 600;
            &:hover {
                color: $medium-gray;
            }
        }
    }
    .account-menu-logout {
        padding: 1rem 2rem 2rem;
    }
    ul.help-menu {
        text-align: center;
        .help-toll {
            text-align: center;
            margin: 1rem 0;
            &-no {
                display: block;
                font-size: 1.5rem;
            }
        }
        .help-other {
            display: flex;
            align-content: space-between;
            align-items: center;
            &-box {
                cursor: pointer;
                background: get-color('light-vanilla');
                border-top: 1px solid darken(get-color('light-vanilla'), 5%);
                border-bottom: 1px solid darken(get-color('light-vanilla'), 5%);
                &:hover {
                    background: darken(get-color('light-vanilla'), 2%);
                }
                flex: 1 1 0px;
                padding: 1rem 0;
                border-right: 1px solid darken(get-color('light-vanilla'), 5%);
                text-align: center;
                a{
                    background: none;
                    &:hover {
                        color: $medium-gray;
                    }
                }
            }
        }
        .help-links {
            margin: 1rem 0;
            a {
                background: linear-gradient(0deg, get-color(primary), get-color(primary)) no-repeat left 100%;
                background-size: 0 2px;
                font-weight: normal;
                padding-bottom: rem-calc(2);
                &:hover {
                    background-size: 100% 2px;
                    background-position-x: left;
                }
            }
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .logo-wrapper {
        min-width: initial;
    }
    .logo {
        margin-left: 0;
    }
}

@media screen and #{breakpoint(large)} {
    %menu-link {
        padding: rem-calc(12 17);
        cursor: pointer;
    }
    .logo {
        img {
            max-width: 150px;
        }
        margin-left: 0;
    }
    .main-header {
        background-color: $white;
        padding: 1.5rem 0;
    }
}

@media screen and #{breakpoint(xlarge)} {
    .logo {
        img {
            max-width: 100%;
        }
    }
}
