@mixin file-uploader {
    .file-upload-wrapper {
        display: block;
        box-sizing: border-box;
        height: rem-calc(46);
        margin: 0 0 1.6rem;
        border: 1px solid transparent;
        border-radius: $global-radius;
        background-color: $white;
        font-size: 1rem;
        line-height: 1;
        transition: border-color 0.25s ease-in-out;
        box-shadow: none;
        padding: 0;
        cursor: pointer;
        position: relative;
        color: $ui-gray;
        &:before {
            content: attr(data-action);
            padding: .85rem 1rem;
            top: 0;
            right: 0;
            position: absolute;
            background: $light-gray;
            color: darken($ui-gray, 10%);
            font-weight: $global-weight-bold;
            border-top-right-radius: $input-radius;
            border-bottom-right-radius: $input-radius;
        }
        &:after {
            display: inline-block;
            position: absolute;
            content: attr(data-placeholder);
            padding: $input-padding;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            box-shadow: $input-shadow;
            border-radius: $input-radius;
        }
        &:focus, &:active {
            &:after {
                border: $input-border-focus;
            }
        }
        .file-upload-placeholder {
            padding: $input-padding;
            color: $input-placeholder-color;
            display: inline-block;
            cursor: pointer;
        }
        .file-upload {
            height: 100%;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            z-index: 3;
            margin: 0;
        }
    }
}
