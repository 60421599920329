.checkout-header {
    background: darken(get-color('light-vanilla'), 2%);
    padding: 1rem 0;

    .secure-seal {
        text-align: center;
        font-family: $sec-font;
        color: $medium-gray;
        &-icon {
            color: $ui-gray;
            font-size: rem-calc(24);
            font-weight: normal;
            vertical-align: middle;
        }

        span {
            font-size: rem-calc(20);
            font-weight: 700;
            vertical-align: middle;
            display: inline-block;
            margin: rem-calc(3 0 0 5);
        }
    }
    .minicart-action {
        margin: 0;
    }
}

.checkout-minicart {
    position: relative;
    top: rem-calc(4);
    padding-right: 1rem;
    .cart-icon {
        color: $medium-gray;
        font-size: rem-calc(24);
    }
    .minicart-content-count {
        right: rem-calc(5);
        left: initial;
        bottom: rem-calc(22);
    }
}
