$callout-icon-margin: strip-unit($callout-icon-size);

%callout-icon-padding{
    padding: $callout-padding;
}
.callout{
    position: relative;
    padding: $callout-note-padding;
    margin: $callout-margin;
    border-left: $callout-border;
    background: $callout-background;
    box-shadow: $callout-box-shadow;
    &:before{
        width: $callout-icon-size;
        height: $callout-icon-size;
        display: inline-block;
        position:absolute;
        top: 50%;
        margin-top: rem-calc(-10);
        z-index: 3;
        left: 1rem;
        filter: opacity(0.85);
    }
    &-content{
        padding: $callout-padding;
        display: inline-block;
    }
    .close-button{
        top: 0;
    }
    p, span {
        margin-bottom: 0;
    }
    > :first-child {
         margin-top: 0;
     }

    > :last-child {
        margin-bottom: 0;
    }
}
.closeable{
    &>:nth-last-child(2){
        margin-bottom: 0;
    }
}
