@mixin box-wrapper($box-bg: get-color('ice'), $box-padding: 1rem, $box-shadow: $global-box-shadow){
  background-color: $box-bg;
  padding: $box-padding;
  box-shadow: $box-shadow;
}

@mixin bordered-box($bg-color: $white, $border-color: get-color('light-green')) {
    background: $bg-color;
    border: 1px solid $border-color;
    border-top-width: 5px;
}
