.checkout-page{
  .footer{
    padding: 1rem 0 1.5rem;
    &.help{
      background-color: get-color('brown');
      color: $white;
      .help-title{
        margin: 0 0 0.5rem;
          font-weight: bold;
          display: inline-block;
      }
      .help-btn{
        border: $global-border;
        border-radius: $global-radius;
        padding: rem-calc(9 9 15);
          margin: 0.5rem 0;
        cursor: pointer;
        [class*="cas-i"], a{
          vertical-align: middle;
        }
        a{
          color: $light-gray;
        }
        &:hover{
          background-color: rgba($light-gray, .3);
        }
      }
    }
    &.content{
      padding: .5rem 0;
    }
  }
  .v-card{
      text-align: center;
      display: inline-block;
  }
  .policy-links{
    margin-bottom: 1rem;
  }
}

@media screen and #{breakpoint(medium)} {
    .checkout-page{
        .footer{
            &.help{
                .help-title{
                    margin: 0 0 1rem;
                }
                .help-btn{
                    margin: 0;
                }
            }
        }
    }
}
