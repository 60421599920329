%off-canvas {
    top: rem-calc(59);
    + .js-off-canvas-overlay {
        top: rem-calc(59);
        background: rgba(get-color('midnight'), .65);
    }
}

.mobile-nav {
    background: url('../images/pattern-bg/paper-bg_2e1e11.jpg') repeat #2e1e11;
    @extend %off-canvas;
    .menu.vertical {
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .drilldown.menu {
        margin: 0;
        padding: 0;
        .is-drilldown-submenu {
            background: url('../images/pattern-bg/paper-bg_2e1e11.jpg') repeat #2e1e11;
        }
        li {
            a {
                display: block;
                color: $white;
                font-weight: 700;
                padding: $drilldown-padding;
            }
            &.account-menu-link {
                margin-top: 1rem;
                padding-top: 1rem;
                border-top: $global-border;
            }
            &.account-menu-logout {
                padding: 0.5rem 1rem;
            }
            &.back-menu {
                margin-top: 0;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }
        }
    }
    .help-section-wrapper {
        margin-top: 1rem;
        margin-left: 0;
        padding: rem-calc(20 0);
        border-top: $global-border;
        li {
            padding: rem-calc(0 17);
            &:before {
                display: none;
                margin-left: 0;
            }
        }
        .help-menu {
            display: block !important;
            li {
                padding: 0;
                .help-toll {
                    display: block;
                    color: $white;
                    margin-top: 1rem;
                }
                .help-toll-no, .help-other-box {
                    display: block;
                    margin: 0.5rem 0;
                }
            }
        }
        .help-section {
            color: $white;
            a {
                color: $white;
            }
        }
        .mobile-break {
            display: block;
            margin-top: 0.25rem;
        }
    }
}

.off-canvas-search {
    @extend %off-canvas;
}

.page-header {
    .mobile-menu {
    margin-top: .25rem;
    font-size: rem-calc(22);
    color: $black;
    &-icon {
        padding: 0 0.5rem;
    }
}
    .nav-active {
        display: none;
    }
    .menu-active {
        .nav-active {
            display: inline-block;
        }
        .nav-inactive {
            display: none;
        }
    }
}
.mobile-acc-nav {
    margin: 1rem 0;
    padding: .5rem 0;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    .account-menu-logout {
        padding: 0.5rem 1rem 1rem;
    }
}

@media screen and #{breakpoint(medium)} {
    .mobile-nav {
        top: rem-calc(78);
        + .js-off-canvas-overlay {
            top: rem-calc(78);
        }
    }
    .page-header {
        .mobile-menu-icon {
            padding: 0 1rem;
        }
    }
}
