.accordion-title {
    font-weight: 700;
    @include disable-mouse-outline;
    cursor: pointer;
    border: 0;
    padding: $accordion-item-padding;
    font-size: $accordion-title-font-size;
    color: $accordion-item-color;
    position: relative;
    display: block;
    text-decoration: none;
    background: none;
    &:active, &:focus {
        color: $accordion-item-color;
    }
    &:hover, &:active {
        color: darken(get-color(secondary), 7.5%);
        border: 0;
        &:before {
            content: '\25BC';
        }
    }
    &:before {
        position: absolute;
        top: 50%;
        right: 1rem;
        margin-top: rem-calc(-10);
        display: inline-block;
        width: 12px;
        height: 12px;
        content: '\25BC';
        color: $medium-gray;
    }
}

.accordion-item {
    border-bottom: 1px solid $light-gray;
    &:last-child {
        border-bottom: 0;
    }
    &.is-active {
        .accordion-title {
            color: darken(get-color(secondary), 7.5%);
            &:before {
                content: '\25B2';
                color: get-color(secondary);
            }
        }
    }
    &:not(.is-active) {
        .accordion-title {
            color: $accordion-item-color;
            &:before {
                content: '\25BC';
            }
        }
    }
}

.accordion-content {
    display: none;
    padding: $accordion-content-padding;
    color: $accordion-content-color;
}

@media screen and #{breakpoint(medium)} {
    .accordion-title {
        &:before {
            width: 17px;
            height: 17px;
        }
    }
}
