.pagination {
    @include clearfix;
    margin: 0;
    .page-label {
        margin-right: 1rem;
    }
    .current {
        font-weight: 800;
        color: $pagination-item-color-current;
    }
    a {
        border-bottom: 0;
        padding: $pagination-item-padding;
        color: $pagination-item-color;
        &:hover {
            color: $primary-color;
        }
    }
    .button-link {
        cursor: pointer;
        color: $medium-gray;
        &:disabled {
            cursor: not-allowed;
            color: $ui-light-gray;
        }
    }
    .pagination-previous, .pagination-next {
        a {
            color: $medium-gray;
        }
    }
    .pagination-previous, .pagination-previous.disabled {
        font-weight: 600;
    }
    .pagination-next, .pagination-next.disabled {
        font-weight: 600;
    }
    .ellipsis::after {
        padding: $pagination-item-padding;
        content: '\2026';
    }
    .pagination-previous.disabled, .pagination-next.disabled {
        color: darken($light-gray, 5%);
    }
    li {
        display: inline-block;
        word-spacing: initial;
        border: 0;
        border-radius: 0;
        margin:0;
        padding: $pagination-item-padding;
        & > span {
            display: inline-block;
            padding: $pagination-item-padding;
        }
        &:before {
            margin: 0;
        }
    }
    &.pg-mobile {
        li {
            & > span {
                margin: 0;
                padding: $pagination-item-padding;
            }
        }
    }
}
