.input-group {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    div.mage-error {
        flex: 0 0 100%;
    }
    &-field {
        flex: 1 1 0px; // sass-lint:disable-line zero-unit
        height: auto;
        min-width: 0;
        position: relative;
        border-radius: $global-radius;
        padding-left: 3.25rem;
        margin-left: -3.25rem;
        z-index: 1;
    }
    &-label {
        margin: 0;
        white-space: nowrap;
        padding: 0 $input-prefix-padding;
        background: $input-prefix-background;
        position: relative;
        z-index: 3;
    }
}
.inline-label {
     margin-bottom: 1rem;
     margin-top: 1rem;
     position: relative;
     .input-group-label {
         position: absolute;
         top: 12px;
         left: 5px;
         padding-left: 0.5rem;
     }
     input {
         margin: 0;
         padding-left: 2.5rem;
     }
 }
input {
    margin: 0;
    padding-left: 2.5rem;
}

@media screen and #{breakpoint(medium)}{
    .input-group {
        &-field {
            padding-top: rem-calc(12);
            padding-bottom: rem-calc(12);
        }
    }
}
