.breadcrumbs {
    margin: 3rem 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding-bottom: rem-calc(9);
    &-mobile {
        .bc-prev {
            color: $medium-gray;
            font-size: rem-calc(12);
            margin-right: .35rem;
        }
    }
    li {
        display: inline;
        position: relative;
        margin: 0 0.35rem 0 0;
        &.home {
            position: relative;
            &:before {
                display: inline-block;
                content: url('#{$svg-icons-path}home.svg');
                width: .8rem;
                height: .8rem;
                vertical-align: top;
                margin-right: .5rem;
            }
        }

        &:not(:last-child)::after {
            position: relative;
            margin: 0 $breadcrumbs-item-margin;
            opacity: 1;
            content: $breadcrumbs-item-separator-item;
            color: $breadcrumbs-item-separator-color;
        }
        a {
            font-weight: $global-weight-normal;
            border-bottom: 0;
            position: relative;
            color: $dark-gray;
            padding-bottom: rem-calc(5);
            text-decoration: none;
            &:hover {
                color: $medium-gray;
            }
        }
        &:hover {
            position: relative;
            &:before {
                border-bottom: 0;
            }
        }
        &:not(:last-child)::after {
            margin-right: .35rem;
            font-size: $breadcrumbs-item-separator-size;
        }
    }
    .current {
        font-weight: $global-weight-bold;
        padding-bottom: rem-calc(3);
        border-bottom: 2px solid get-color(primary);
    }
}
