.account-form-wrapper {
    padding: 0 1rem;
    margin-bottom: 2rem;
    .page-title {
        @extend .work-sans;
        margin-top: 0;
        margin-bottom: 0;
    }
    .form-subtitle {
        font-weight: 400;
        display: inline-block;
    }
}

.input-group.show-pass {
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0;
    input {
        margin-bottom: 0;
    }
    .input-group-label {
        position: absolute;
        top: 0.5rem;
        right: 0;
        z-index: 99;
        a {
            border: 0;
            color: $ui-light-gray;
            font-size: rem-calc(14);
            font-weight: 800;
            &:hover {
                color: $ui-gray;
            }
        }
    }
}

.other-account-link {
    border-top: 1px solid $light-gray;
    padding-top: 2rem;
    margin-top: 2rem;
}

.account-form-wrapper {
    .sub {
        &-disclaimer {
            margin-top: -.5rem;
        }
    }
}

.sub {
    &-me {
        margin: 1rem 0;
        label {
            font-weight: 600;
        }
    }
    &-disclaimer {
        font-style: italic;
        display: inline-block;
    }
}

.modal-form {
    .sub {
        &-disclaimer {
            margin-top: -1.5rem;
        }
    }
    .res-message {
        display: none;
    }
    @include callout(success);
    @include callout(alert);
}


@media screen and #{breakpoint(small only)} {
    .mobile-link-button {
        @include link-to-hollow-btn;
        margin: 1rem auto 0;
        width: 100%;
        font-weight: 700;
    }
}

@media screen and #{breakpoint(medium)} {
    .account-form {
        &-wrapper {
            background: $white;
            border: 1px solid get-color('light-green');
            border-top-width: 5px;
            padding: 3rem 4rem 1rem;
            margin: 3rem auto;
            .form-subtitle {
                font-weight: 700;
            }
            .sub {
                &-me {
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .other-account-link {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
    }
    .mobile-link-button {
        margin-left: .5rem;
    }
    .sub-me {
        margin-bottom: 0.5rem;
    }
}

@media screen and #{breakpoint(large)} {
    .account-form-wrapper {
        .sub {
            &-me {
                margin-bottom: 0;
                > label {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}
