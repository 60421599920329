.message {
    position: relative;
    padding: $callout-note-padding;
    margin: $callout-margin;
    border-left: $callout-border;
    background: $callout-background;
    &:before{
        width: $callout-icon-size;
        height: $callout-icon-size;
        display: inline-block;
        position:absolute;
        top: 50%;
        margin-top: rem-calc(-10);
        z-index: 3;
        left: 1rem;
        filter: opacity(0.85);
    }
    &.error {
        background: $cas-alert-overlay;
        padding: $callout-padding;
        border-color: get-color(alert);
        &:before{
            content: url('#{$svg-icons-path}error.svg');
        }
    }
    &.success {
        background: $cas-success-overlay;
        padding: $callout-padding;
        border-color: get-color(success);
        &:before{
            content: url('#{$svg-icons-path}success.svg');
        }
    }
}
