table thead th, table thead td {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: em-letter-spacing(50);
}

table tbody th, table tbody td {
    text-align: left;
    vertical-align: top;
}

%spacious-padding {
    padding: 1rem;
}
%border {
    border: 1px dotted $light-gray;
}

table {
    &.spacious {
        thead {
            th, td {
                @extend %spacious-padding;
            }
        }
        tbody {
            th, td {
                @extend %spacious-padding;
            }
        }
    }
    &.bordered {
        thead {
            th, td {
                @extend %border;
            }
        }
        tbody {
            th, td {
                @extend %border;
            }
        }
    }
}

.table-title {
    margin-bottom: 1rem;
    margin-left: 1rem;
}
