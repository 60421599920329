.step-container {
    @extend %checkout-panel;
    margin-top: 2rem;
    fieldset {
        border: 0;
        padding: 0;
        margin-bottom: 2rem;
    }
    .button {
        margin: 1rem 0;
    }
}

.step-title-label {
    border-radius: $global-radius $global-radius 0 0;
    display: block;
    padding: 0.5rem 1rem;
    background: get-color('brown');
    .step-title {
        font-size: rem-calc(22);
        font-weight: 900;
        @extend %work-sans;
        color: $off-white;
        margin: 0;
        &:before {
            display: inline-block;
            color: $off-white;
            margin-right: 0.5rem;
        }
        &.shipping {
            &:before {
                content: "1.";
            }
        }
        &.review {
            &:before {
                content: "3. ";
            }
        }
    }
}
.steps-wrapper-content {
    padding: 1rem;
    &.checkout-shipping-method {
        display: flex;
        flex-wrap: wrap;
        .shipping-method-content, .opc-block-shipping-information {
            flex: 0 0 100%;
        }
    }
}
.step-container {
    .methods-shipping {
        .method-title {
            font-weight: 700;
            color: get-color('blue');
        }
        legend {
            margin-bottom: 1.5rem;
        }
        [type='checkbox'] + label, [type='radio'] + label {
            margin-right: 0;
        }
    }
    .shipping-information {
        border: $global-border;
        border-radius: $global-radius;
        padding: 1rem;
        margin-bottom: 2rem;
        &.select-address {
            margin-bottom: 1rem;
            padding-bottom: 0;
            .shipping-information-action {
                margin-left: 2rem;
                margin-top: .75rem;
            }
        }
    }
    .shipping-information-title {
        font-weight: 700;
        margin-bottom: 0.5rem;
        color: $medium-gray;
        text-transform: uppercase;
    }
    .shipping-information-content {
        input[type='radio'] {
            top: 1.35rem;
            + label {
                margin-left: 2rem;
            }
        }
    }
    .add-new-address {
        border: $global-border;
        border-radius: $global-radius;
        font-weight: 700;
        width: 100%;
        padding: 1rem 0;
        margin-bottom: 1.5rem;
        color: $medium-gray;
        cursor: pointer;
        &:hover {
            background: get-color('ice');
        }
        .plus-sign {
            font-size: rem-cal(20);
            padding-right: rem-calc(5);
        }
    }
}


@media screen and #{breakpoint(medium)} {
    .step-container {
        .large-inline {
            display: inline-block;
            width: calc(50% - .75rem);
            margin-right: .5rem;
            &.last {
                margin-right: 0;
            }
        }
    }
    .step-title-label {
        padding: 1rem 2rem;
    }
    .steps-wrapper-content {
        padding: 2rem;
    }
}

@media screen and #{breakpoint(large)} {
    .step-container {
        .shipping-information {
            &.select-address {
                .shipping-information-action {
                    margin-left: 0;
                    margin-top: 1rem;
                }
            }
        }
    }
    .step-title-label {
        .step-title {
            font-size: rem-calc(25);
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    .steps-wrapper-content {
        &.checkout-shipping-method {
            .opc-block-shipping-information {
                flex: 0 0 auto;
            }
            .shipping-method-content {
                flex: 1 1 0;
                padding-left: 3rem;
            }
            fieldset {
                margin-top: 0;
            }
        }
    }
}
