.is-invalid-input:not(:focus) {
    background: $input-background-invalid;
}

input.mage-error:not(:focus) {
    background: $input-background-invalid;
}

.is-invalid-input {
    margin-bottom: 0;
}

.form-error-msg, .form-error, label.error {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    font-style: italic;
    position: relative;
    line-height: 1.2;
    font-size: $input-error-font-size;
    font-weight: $input-error-font-weight;
    color: $input-error-color;
}

.mage-error {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    display: block;
    font-size: $input-error-font-size;
    font-weight: $input-error-font-weight;
    color: $input-error-color;
}

label.error {
    margin-top: -1rem;
}

.form-error-msg, .form-error {
    display: none;
    &.ui-comp {
        display: block;
    }
}

fieldset {
    .form-error-msg {
        margin-top: -.5rem;
        margin-bottom: .5rem;
    }
}

.input-group.inline-label {
    + .form-error-msg {
        margin-top: 0.25rem;
    }
}
