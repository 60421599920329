@mixin accordion-panel($panel-color:'light', $padding: 2rem) {
  @if ($panel-color == 'dark') {
    .midnight-panel {
      background: $black;
      padding: $padding;
      .accordion-title {
        color: $light-gray;
      }
      .accordion-item {
        border-bottom: 1px solid $dark-gray;
        &:last-child {
          border-bottom: 0;
        }
      }
      .accordion-content {
        color: $light-gray;
      }
    }
  } @else {
    .ice-panel {
      background: $white;
      padding: $padding;
    }
  }
}
