.label {
    display: inline-block;
    padding: $label-padding;
    border-radius: $label-radius;
    font-size: $label-font-size;
    line-height: 1;
    white-space: nowrap;
    border: 2px solid $label-background;
    color: $label-color;
    font-weight: $label-font-weight;
    &.exclusive, &.magenta {
        border-color: get-color('magenta');
        color: get-color('magenta');
    }
    &.alert, &.orange {
        border-color: get-color('orange');
        color: get-color('orange');
    }
    &.success, &.green {
        border-color: get-color('green');
        color: get-color('green');
    }
}
