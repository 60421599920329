// Custom Base styles for CAS
// - - - - - - - - - - - - - - -
// These are custom styles applied to basic HTML tags, including:
//   - Global word spacing
//   - Links
//   - Lists
//   - Blockquotes
//   - Citations
//   - Code

/// Default value for global word spacing
$cas-global-word-spacing: .06em !default;

/// Default value for blockquote
$cas-blockquote-border-radius: rem-calc(20) !default;
$cas-blockquote-font-size: rem-calc(18);
$cas-cite-margin-top: .5em;

/// Default value for the custom list
$cas-list-item-side-margin: .5rem !default;
$cas-list-marker: mix($light-gray, $medium-gray, 50%) !default;
$cas-defnlist-margin-top: 1rem !default;

/// Default value for the custom links
$cas-links-border-bottom: 2px solid get-color(primary) !default;
$cas-links-line-height: 1.1 !default;
$cas-links-font-weight: 600 !default;

@media print {
    a[href] {
        text-decoration: none;

        &:after {
            content: none !important;
        }
    }
}

//Set word spacing for text
body {
    word-spacing: $cas-global-word-spacing;
}

ul, ol, dl, p, span {
    line-height: $global-lineheight;
}

// Links
a {
    line-height: $cas-links-line-height;
    font-weight: $cas-links-font-weight;
    background: linear-gradient(0deg, get-color(primary), get-color(primary)) no-repeat left 100%;
    background-size: 0 2px;
    transition: all 0.35s;
    color: $anchor-color;
    padding-bottom: rem-calc(2);

    &:hover {
        background-size: 100% 2px;
        background-position-x: left;
        text-decoration: none;
    }
    &:focus {
        outline: 2px dotted $ui-gray;
    }

}

//Lists
ul, ol {
    li {
        margin-top: rem-calc(5);
        margin-bottom: rem-calc(5);
    }
}

ul {
    list-style-type: none;

    li {
        &:before {
            border-radius: 50%;
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            margin-left: -$cas-list-item-side-margin *1.5;
            margin-right: $cas-list-item-side-margin;
            vertical-align: middle;
            background: $cas-list-marker;
        }
    }
}

ol {
    margin-left: 2.5rem;
    counter-reset: item;
    list-style-type: none;

    li {
        &:before {
            text-indent: -2rem;
            counter-increment: item;
            content: counter(item) ".";
            color: $cas-list-marker;
            display: inline-block;
            font-size: $global-font-size;
        }
    }

    &.lower-alpha {
        li {
            &:before {
                content: counter(item, lower-alpha) ".";
            }
        }
    }

    ol {
        margin-left: 2.5rem;
        counter-reset: item;

        li {
            &:before {
                content: counters(item, ".") ".";
            }
        }
    }

    ul {
        margin-left: 2.5rem;
        list-style-type: none;

        li {
            &:before {
                counter-reset: item;
                content: "";
            }
        }
    }
}

ul, ol {
    &.no-bullet, &.breadcrumbs, &.pagination, &.tabs {
        list-style-type: none;

        li {
            padding-left: 0;
            text-indent: initial;

            &:before {
                background: none;
                width: 0;
                height: 0;
                margin: 0;
                display: none;
            }
        }
    }
}

dl {
    dt {
        margin-top: $cas-defnlist-margin-top;
    }
}

// Blockquotes
blockquote {
    &.compact-bq {
        padding: 1.5rem 0;

        &, p {
            font-size: rem-calc(17);
        }

        &:after {
            width: rem-calc(50);
            height: rem-calc(28.5);
        }
    }
    border: 0;
    margin: $paragraph-margin-bottom 0 $paragraph-margin-bottom;
    position: relative;

    &, p {
        font-size: $cas-blockquote-font-size;
    }
    &:before {
        position: absolute;
        left: rem-calc(-15);
        top: rem-calc(-5);
        font-size: rem-calc(50);
        content: '\201C';
        display: inline-block;
        width: rem-calc(20);
        height: rem-calc(20);
        line-height: 1;
    }
    &:after {
        position: absolute;
        right: rem-calc(-15);
        bottom: rem-calc(-5);
        font-size: rem-calc(50);
        content: '\201D';
        display: inline-block;
        width: rem-calc(50);
        height: rem-calc(50);
    }
}

// Citations
cite {
    margin-top: $cas-cite-margin-top;
    font-style: normal;
}

//Code
code {
    border-radius: .25rem;
}

address {
    font-style: initial;
}

.show-for-sr {
    color: transparent;
}

@include callout(success);
@include callout(error);
@include callout(alert);
