.modal-popup {
    display: none;
    position: fixed;
    z-index: $reveal-zindex !important;
    background-color: $reveal-overlay-background;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    &._show, &.show {
        display: block;
        overflow-y: scroll;
    }
    &._hide, &.hide {
        display: none;
    }
    .modal-inner-wrap {
        max-width: $global-width;
        padding: $reveal-padding;
        border-radius: $reveal-radius;
        background: $reveal-background;
        box-shadow: $reveal-box-shadow;
        margin: 0 auto;
        position: relative;
        top: 0%;
        width: 100%;
        [type='checkbox'], [type='radio'] {
            margin-bottom: 0;
            + label {
                margin-right: 0;
            }
        }
    }
    .modal-footer {
        text-align: right;
        margin-top: 2rem;
        margin-bottom: 0;
    }
    .action-secondary, .action.secondary {
        color: $ui-gray;
        background: transparent;
        box-shadow: none;
        text-shadow: none;
        @include disable-mouse-outline;
        font-weight: $global-weight-bold;
        border: 0;
        padding: rem-calc(10 34);
        transition: $button-transition;
        cursor: pointer;
        &:hover, &:active, &:focus {
            color: darken($ui-gray, 20%);
        }
    }
    .action-primary, .action.primary {
        @extend .button;
    }
}

body {
    &._has-modal {
        height: 100%;
        overflow: hidden;
        width: 100%;
    }
}

.modal-slide {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease;
    z-index: $reveal-zindex !important;
    background-color: $reveal-overlay-background;
    &._show, &.show {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease;
        .modal-inner-wrap {
            transform: translate(0, 0);
        }
    }
    &._hide, &.hide {
        visibility: hidden;
        opacity: 0;
    }
    .modal-inner-wrap {
        max-width: $global-width;
        padding: $reveal-padding;
        border-radius: $reveal-radius;
        background: $reveal-background;
        box-shadow: $reveal-box-shadow;
    }
    .modal-footer {
        text-align: right;
        margin-top: 2rem;
        margin-bottom: 0;
    }
    .action-secondary, .action.secondary {
        color: $ui-gray;
        background: transparent;
        box-shadow: none;
        text-shadow: none;
        @include disable-mouse-outline;
        font-weight: $global-weight-bold;
        border: 0;
        padding: rem-calc(10 34);
        transition: $button-transition;
        cursor: pointer;
        &:hover, &:active, &:focus {
            color: darken($ui-gray, 20%);
        }
    }
    .action-primary, .action.primary {
        @extend .button;
    }

}

@media screen and #{breakpoint(medium)} {
    .modal-popup {
        .modal-inner-wrap {
            width: 100%;
            top: 15%;
        }
    }
}

@media screen and #{breakpoint(large)} {
    .modal-popup {
        &._show, &.show {
            overflow-y: hidden;
        }
        .modal-inner-wrap {
            width: 75%;
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    .modal-popup {
        .modal-inner-wrap {
            width: 40%;
        }
    }
}
