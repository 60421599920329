.off-canvas-search {
    height: rem-calc(70);
    padding: .75rem 1rem;
    background-color: $light-gray;
    .pill-form {
        input {
            border-color: $ui-light-gray;
        }
        .button.icon {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
.search-col {
    flex: 0 0 100%;
    width: 100%;
}
#algoliaAutocomplete{
    margin-top: 1rem;
    margin-bottom: 0 !important;
    .aa-Form {
        display: flex;

        .aa-SubmitButton {
            width: rem-calc(45);
            height: rem-calc(45);
            background-color: get-color(primary);
            cursor: pointer;
            &:hover {
                background-color: $button-background-hover;
            }
        }
        .aa-SubmitIcon {
            fill: $white;
        }
    }
    .aa-InputWrapper {
        flex: 1 1 auto;
        margin-right: 0.5rem;
        input[type=search] {
            width: 100%;
            margin: 0;
            padding-left: rem-calc(20);
        }
    }
    .aa-InputWrapperSuffix {
        flex: 0 0 45px;
    }
}


.aa-Panel .aa-List .aa-Item {
    &:before {
        display: none;
    }
    .algoliasearch-autocomplete-hit {
        .info {
            color: $dark-gray;
        }
    }
    .algoliasearch-autocomplete-price {
        color: get-color(primary);
    }
}

@media screen and #{breakpoint(medium)} {
    #algoliaAutocomplete {
        margin-top: 0;
    }
    .search-col {
        flex: 1 1 auto;
        width: auto;
    }
    .header-right-col {
        flex: 0 0 auto;
    }
}

@media screen and #{breakpoint(large)} {
    .header-search {
        .form {
            margin-left: rem-calc(17);
        }
        .pill-form .button.icon {
            padding-top: rem-calc(11);
            padding-bottom: rem-calc(11);
        }
    }
}
