.amazon-widget-container{
  .amazon-widget{
    height: 300px;
  }
}
.step-container .button {
    &.revert-checkout{
        margin: 0 0 2rem;
    }
}
