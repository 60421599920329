.opc-payment {
    margin-top: 0;
}
.payments-group {
    margin-top: 2rem;
}

.payment-method-content {
    margin-top: 1rem;
}

.billing-address-form {
    .field-tooltip {
        display: none;
    }
}

%payment-wrapper {
    background: get-color('light-vanilla');
    border: 1px solid darken(get-color('light-vanilla'), 5%);
    padding: 1.5rem;
    margin-bottom: 2rem;
    .title {
        margin: .5rem 0;
    }
}
.payment-method-option {
    @extend %payment-wrapper;
}

.store-credit-option {
    @extend %payment-wrapper;
    margin-bottom: 0;
    padding-bottom: 0;
    &.sc {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    &.gc {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .store-credit-icon {
        filter: grayscale(100%);
        opacity: .5;
        vertical-align: bottom;
    }
    .credit-balance {
        font-family: $work-sans;
        font-size: rem-calc(20);
    }
}

.step-container .store-credit-option {
    .button {
        margin-top: 0.5rem;
        padding: rem-calc(12 34);
    }
}

.form-store-credit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid $ui-light-gray;
    padding-bottom: 1.5rem;
}

.form-gift-card {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding-bottom: 2rem;
    .input-text {
        margin-bottom: 1rem;
    }
}

.vault-method-title {
    margin-left: 1rem;
    .vault-title {
        font-size: rem-calc(20);
        font-weight: 700;
        display: block;
        margin-bottom: 1rem;
    }
}

.paypal-btn-action {
    display: inline-block;
    margin: 0.5rem 0 0.75rem;
    min-width: rem-calc(240);
    max-width: rem-calc(240);
}

.cc-method {
    margin-bottom: 1rem;
    .cc-tag {
        display: inline-block;
        font-weight: 700;
    }
    .control {
        display: inline-block;
    }
}


.no-payment-required {
    margin-bottom: 2rem;
    label {
        margin: 0;
        &:before, &:after {
            display: none;
        }
    }
}

.credit-card-types {
    .item {
        display: inline-block;
        margin: 0 .25rem;
        border: 1px solid transparent;
        &._inactive {
            filter: grayscale(100%);
            opacity: .5;
        }
    }
    .tips-icon {
        margin-top: -5px;
    }
}


%billing-address-box {
    border: 1px solid darken($light-gray, 10%);
    border-radius: $global-radius;
    background-color: $white;
    padding: 1rem;
}

.billing-address-details {
    @extend %billing-address-box;
}

.billing-information-title {
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: $medium-gray;
    text-transform: uppercase;
}

.checkout-billing-address {
    margin-top: .5rem;
    margin-bottom: 2rem;
}

.new-billing {
    @extend %billing-address-box;
    padding: 2rem 1.5rem;
}

.field-select-billing {
    .control {
        padding: 0 .75rem;
    }
    label {
        line-height: 1.2;
        margin-top: .75rem;
        margin-bottom: .75rem;
    }
}

.add-bill-address {
    margin: 1rem 0;
    @extend a;
    span {
        font-weight: 700;
        vertical-align: baseline;
        &.plus-icon {
            font-size: rem-calc(24);
            margin-right: rem-calc(5);
        }
    }
}

.control {
    .mage-error {
        font-style: italic;
        font-size: rem-calc(15);
        color: get-color('orange');
        font-weight: 600;
        margin: 0.5rem 0 1rem;
    }
    input.mage-error {
        margin: 0.5rem 0 0;
    }
    .cvv {
        margin-top: 0.5rem;
    }
}

.field {
    .field-tooltip {
        display: inline-block;
        vertical-align: top;
        top: 3px;
        position: relative;
        z-index: 1;
        width: 0;
        overflow: visible;
        &.active {
            .field-tooltip-content {
                display: block;
                color: $white;
            }
        }
    }
    .field-tooltip:hover {
        z-index: 99;
    }
    .field-tooltip-action {
        position: relative;
        z-index: 2;
        width: 17px;
        height: 17px;
        display: inline-block;
        cursor: help;
        line-height: 1;
    }
    .field-tooltip-action:before {
        content: url(../images/icons/help.svg);
        display: inline-block;
        overflow: hidden;
        height: 17px;
        width: 17px;
    }
    .field-tooltip-content {
        display: none;
        position: absolute;
        z-index: 1;
        width: 420px;
        background: $dark-gray;
        padding: 1rem;
        right: -460px;
        border-radius: $global-radius;
        bottom: 50%;
        margin-bottom: -96px;
        box-shadow: $global-box-shadow;
    }
    .field-tooltip-content:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-right-color: $dark-gray;
        position: absolute;
        right: 100%;
        top: 50%;
        margin-top: -6px;
        z-index: 3;
    }
}


.payment-list-wrap {
    margin-bottom: 1rem;
    border: 1px solid $ui-light-gray;
    border-radius: $global-radius;
    padding: 1rem;
    label {
        margin-bottom: 0;
    }
    &.is-checked {
        border-color: get-color('secondary');
        background-color: rgba(get-color('secondary'), 5%);

    }
    .field.cvv.inline {
        display: flex;
        align-items: center;
        margin: 0.5rem 0 1rem;
        label {
            margin: 0 0.5rem 0 0;
        }
        .bt-input-wrapper {
            max-width: rem-calc(100);
        }
        .bt-input-wrapper, .hosted-control [type="cvv"] {
            margin: 0;
        }
    }
}

.hosted-control {
    max-height: rem-calc(45);
    [type='number'], [type='expirationDate'], [type='expirationMonth'], [type='expirationYear'], [type='cvv'] {
        border: $input-border !important;
        padding: rem-calc(0 15);
    }
    + .hosted-error {
        font-size: $input-error-font-size;
        font-weight: $input-error-font-weight;
        color: $input-error-color;
        flex: 0 0 100%;
        margin-top: 0.5rem;
        display: none;
    }
    &.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
        + .hosted-error {
            display: flex;
        }
    }
}

.bt-input-wrapper {
    margin-top: 0.25rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    display: flex;
}

.field-tooltip-content {
    &.action-vault {
        bottom: 100%;
        &:before {
            top: 15%;
        }
    }
}
.order-comment-form, .order-comment-input {
    margin-top: 1rem;
}

.cc {
    &-masked {
        margin-left: .75rem;
        font-weight: 700;
    }
    &-exp {
        margin-left: 2rem;
    }
    &-new {
        @extend a;
        margin-left: -0.35rem !important;
        span {
            font-weight: 700;
        }
        &:before, &:after {
            display: none;
        }
        &-plus {
            font-size: rem-calc(23);
            display: inline-block;
            margin-right: .5rem;
        }
    }
}

.paypal {
    .actions-toolbar {
        margin-top: 1rem;
    }
    .payment-method-note {
        margin: 1rem 0 2rem;
    }
}
@media screen and #{breakpoint(large)} {
    .step-container .store-credit-option {
        .button {
            margin: 0 0 0 1rem;
        }
        .input-text {
            margin: 0;
        }
    }
}
