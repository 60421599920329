$cas-tabs-box-shadow: 0px 3px 0px 0px $white, 0px 0 2px 0px rgba(get-color('midnight'), 0.3);
$cas-vertical-tabs-box-shadow: 4px 0 0 0 $white, 1px 0px 2px 0px rgba(get-color('midnight'), 0.3);

$cas-tabs-first-child-box-shadow: 0px 3px 0px 0px $white, 1px 0 2px 0px rgba(get-color('midnight'), 0.3);
$cas-tabs-last-child-box-shadow: 0px 3px 0px 0px $white, -1px 0 2px 0px rgba(get-color('midnight'), 0.3);

$cas-vertical-tabs-first-child-box-shadow: 2px 0px 0px 0px $white, 0px 1px 2px 0px rgba(get-color('midnight'), 0.3);
$cas-vertical-tabs-last-child-box-shadow: 4px 0 0px 0px $white, 0px -1px 2px 0px rgba(get-color('midnight'), 0.3);
$cas-vertical-tabs-padding: rem-calc(15.5) 2rem;

$cas-tabs-content-box-shadow: 0 1px 2px 0px rgba(get-color('midnight'), 0.1);
$cas-tabs-border-radius: $global-radius;

@mixin tabs-container(
    $margin: $tab-margin,
    $background: $tab-background,
    $border-color: $tab-content-border
) {
    @include clearfix;
    margin: $margin;
    border: 1px solid $border-color;
    background: $background;
    list-style-type: none;
}


.tabs-icon {
    width: 17px;
    height: 17px;
    fill: $ui-gray;
    vertical-align: top;
    @include breakpoint(xlarge) {
        margin-right: rem-calc(10);
    }
}

.tabs-wrapper {
    display: inline-block;
    border-radius: $cas-tabs-border-radius;
    border-top: 3px solid transparent;
    &.vertical {
        display: block;
        border-radius: $cas-tabs-border-radius;
        box-shadow: $cas-tabs-content-box-shadow;
        border: 1px solid $light-gray;
        border-top: 0;
        border-left: 0;
    }
}

.tabs {
    @include tabs-container;
    border-bottom: 0;
    border-top-left-radius: $cas-tabs-border-radius;
    border-top-right-radius: $cas-tabs-border-radius;

    &.vertical {
        border-radius: 0;
        border-top-left-radius: $cas-tabs-border-radius;
        border-bottom-left-radius: $cas-tabs-border-radius;
        > li {
            display: block;
            float: none;
            width: auto;
        }
    }
    li {
        margin-top: 0;
        margin-bottom: 0;
        &:first-child {
            a {
                border-top-left-radius: $cas-tabs-border-radius;
            }
        }
    }
}

.tabs-title {
    float: left;
    &:before {
        display: none;
    }
    > a {
        display: block;
        padding: $tab-item-padding;
        line-height: 1;
        color: $tab-color;
        border-top: 3px solid transparent;
        border-bottom: 0;
        position: relative;
        @include disable-mouse-outline;
        &:hover {
            border-top: 3px solid get-color('cyan');
            border-bottom: 0;
            box-shadow: $cas-tabs-box-shadow;
            background: $tab-item-background-hover;
            color: scale-color($tab-color, $lightness: -14%);
        }
        &:focus,
        &[aria-selected='true'] {
            background: $tab-background-active;
            color: $tab-active-color;
        }
    }
    &.is-active {
        a {
            background: $tab-background-active;
            color: get-color('blue');
            font-weight: 700;
            border-top: 3px solid get-color('cyan');
            box-shadow: $cas-tabs-box-shadow;
            @include disable-mouse-outline;
        }
        svg {
            fill: get-color('cyan');
        }
        &:first-child {
            a {
                box-shadow: $cas-tabs-first-child-box-shadow;
            }
        }
        &:last-child {
            a {
                box-shadow: $cas-tabs-last-child-box-shadow;
            }
        }
    }
    &:first-child {
        border-top-left-radius: $cas-tabs-border-radius;
        a {
            &:hover {
                box-shadow: $cas-tabs-first-child-box-shadow;
            }
        }
    }
    &:last-child {
        a {
            &:hover {
                box-shadow: $cas-tabs-last-child-box-shadow;
            }
        }
    }
}
.tabs-content {
    border: 1px solid $tab-content-border;
    border-top: 0;
    background: $tab-content-background;
    color: $tab-content-color;
    transition: all 0.5s ease;
    &.vertical {
        border-left: 0;
        border-radius: 0;
        border-top-left-radius: $cas-tabs-border-radius;
        border-bottom-left-radius: $cas-tabs-border-radius;
    }
}
.tabs-panel {
    display: none;
    padding:$tab-content-padding;
    &.is-active, &.active {
        display: block;
    }
}

.tabs.vertical {
    .tabs-title {
        &:before {
            display: none;
        }
        a {
            border: 0;
            padding: $cas-vertical-tabs-padding;
            border-left: 3px solid transparent;
            &:hover {
                border-top: 0;
                border-left: 3px solid get-color('cyan');
                box-shadow: $cas-vertical-tabs-box-shadow;
            }
        }
        &.is-active {
            a {
                border-top: 0;
                border-left: 3px solid get-color('cyan');
                box-shadow: $cas-vertical-tabs-box-shadow;
                @include disable-mouse-outline;
            }
            &:first-child {
                a {
                    box-shadow: $cas-vertical-tabs-first-child-box-shadow;
                }
            }
        }
        &:first-child {
            border-top-left-radius: $cas-tabs-border-radius;
            a {
                &:hover {
                    box-shadow: $cas-vertical-tabs-first-child-box-shadow;
                }
            }
        }
        &:last-child {
            border-radius: 0;
            a {
                border-radius: 0;
                &:hover {
                    box-shadow: $cas-vertical-tabs-last-child-box-shadow;
                }
            }
        }
    }
}

.tabs-content {
    box-shadow: $cas-tabs-content-box-shadow;
    border-bottom-left-radius: $cas-tabs-border-radius;
    border-bottom-right-radius: $cas-tabs-border-radius;
    &.vertical {
        box-shadow: none;
        border-top: 1px solid $light-gray;
        border-radius: 0;
        border-top-right-radius: $cas-tabs-border-radius;
        border-bottom-right-radius: $cas-tabs-border-radius;
    }
}

