.order-status {
    display: block;
    .label {
        font-size: rem-calc(14);
    }
    &-title {
        font-size: rem-calc(16);
        font-weight: 700;
        display: inline-block;
        margin: .5rem 0;
    }

    &-box {
        border: 1px dotted $ui-gray;
        border-radius: $global-radius;
        padding: 1rem 0.5rem;
        margin: 0 0 0.5rem;
        display: inline-block;
    }

    &-icon {
        color: $ui-gray;
        font-size: rem-calc(32);
        margin-right: 1.5rem;
    }

    &-detail {
        text-align: left;
    }

    &-number {
        display: block;
        margin-bottom: .5rem;
        font-weight: 700;
    }
}

.order-status-box {
    .icon-box {
        display: inline-block;
        width: 20%;
        vertical-align: top;
    }

    .detail-box {
        display: inline-block;
        width: calc(80% - 1rem);
        vertical-align: top;
        text-align: left;
    }
}

.dropdown.menu .menu-link .is-dropdown-submenu a.order-status-url {
    @extend a;
    color: get-color('blue');
    border-bottom-color: $ui-light-gray;
    padding: 0;
    font-weight: 700;
    margin-top: .5rem;
}
