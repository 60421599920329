.aside-wrapper {
    background-color: get-color('light-vanilla');
    border: 1px solid get-color('light-green');
    border-bottom-width: 5px;
    padding: 1rem;
    margin-bottom: 2rem;
}
@media screen and #{breakpoint(medium)} {
    .aside-wrapper {
        &.sub-pref {
            padding: 1rem 2rem;
        }
    }
}
@media screen and #{breakpoint(large)} {
    .aside-wrapper{
        margin-left: 2rem;
        margin-bottom: 0;
    }
}

.help-box {
    margin-top: 2rem;
    border: $card-border;
    border-radius: $global-radius;
}

.help-card {
    padding: 1.5rem 0;
    &.text-left {
        text-align: left;
    }
    &-title {
        margin: 0 0 1rem;
        font-weight: 700;
    }
    &-icons {
        display: block;
        margin: 0 auto .5rem;
        color: $ui-light-gray;
        font-size: rem-calc(30);
    }
    &-item {
        margin: 1rem 0;
        border-bottom: $global-border;
        padding-bottom: 1rem;
        &:last-of-type {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        .default-icons {
            font-size: rem-calc(22);
        }
    }
}
